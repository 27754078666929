import { PropsSVG } from "./types";

function ContactBrochureIcon({ height = 32, width = 32, ...props }: PropsSVG) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.4962 26.0735C6.56399 27.0364 7.32139 27.8068 8.28338 27.8875C13.5789 28.3314 18.4211 28.3314 23.7166 27.8875C24.6786 27.8068 25.436 27.0364 25.5038 26.0735C25.9154 20.2268 25.9154 14.703 25.5038 8.85636C25.436 7.89338 24.6788 7.12535 23.7207 7.00764C21.246 6.70363 18.6603 6.3125 16 6.3125C13.3397 6.3125 10.754 6.70363 8.27935 7.00764C7.32119 7.12535 6.56399 7.89338 6.4962 8.85636C6.0846 14.703 6.0846 20.2268 6.4962 26.0735Z"
        fill="#01C246"
        stroke="#3C3C3C"
        strokeWidth="1.55"
      />
      <path
        d="M16.775 14C16.775 13.572 16.428 13.225 16 13.225C15.572 13.225 15.225 13.572 15.225 14H16.775ZM20.5504 19.3456C20.8518 19.0416 20.8496 18.5509 20.5456 18.2496C20.2416 17.9483 19.7509 17.9504 19.4496 18.2544L20.5504 19.3456ZM18.6022 20.2104L19.1526 20.7559H19.1526L18.6022 20.2104ZM13.3978 20.2104L13.9483 19.6648H13.9483L13.3978 20.2104ZM12.5504 18.2544C12.2491 17.9504 11.7584 17.9483 11.4544 18.2496C11.1504 18.5509 11.1482 19.0416 11.4496 19.3456L12.5504 18.2544ZM16.2507 21.9841L16.3487 22.7529H16.3487L16.2507 21.9841ZM15.7493 21.9841L15.6513 22.7529H15.6513L15.7493 21.9841ZM15.225 21.9841C15.225 22.4121 15.572 22.7591 16 22.7591C16.428 22.7591 16.775 22.4121 16.775 21.9841H15.225ZM15.225 14V21.2H16.775V14H15.225ZM19.4496 18.2544L18.0517 19.6648L19.1526 20.7559L20.5504 19.3456L19.4496 18.2544ZM13.9483 19.6648L12.5504 18.2544L11.4496 19.3456L12.8474 20.7559L13.9483 19.6648ZM18.0517 19.6648C17.4815 20.2401 17.0991 20.6243 16.7779 20.8823C16.4688 21.1305 16.2924 21.1975 16.1527 21.2153L16.3487 22.7529C16.8883 22.6841 17.3302 22.4266 17.7484 22.0908C18.1544 21.7648 18.6081 21.3054 19.1526 20.7559L18.0517 19.6648ZM12.8474 20.7559C13.3919 21.3054 13.8456 21.7648 14.2516 22.0908C14.6698 22.4266 15.1117 22.6841 15.6513 22.7529L15.8473 21.2153C15.7076 21.1975 15.5312 21.1305 15.2221 20.8823C14.9009 20.6243 14.5185 20.2401 13.9483 19.6648L12.8474 20.7559ZM16.1527 21.2153C16.0513 21.2282 15.9487 21.2282 15.8473 21.2153L15.6513 22.7529C15.8829 22.7824 16.1171 22.7824 16.3487 22.7529L16.1527 21.2153ZM15.225 21.2V21.9841H16.775V21.2H15.225Z"
        fill="white"
      />
      <rect
        x="10.3438"
        y="3.5"
        width="11.3125"
        height="5.9375"
        rx="2.96875"
        fill="#F1F0EE"
        stroke="#3C3C3C"
        strokeWidth="1.55"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ContactBrochureIcon;
