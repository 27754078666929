import { ETenure } from "../enums/tenure";
import { EUnitType } from "../enums/UnitType";

import ContactBrochureIcon from "~/components/atoms/Icons/ContactBrochureIcon";
import ContactPriceListIcon from "~/components/atoms/Icons/ContactPriceListIcon";
import ContactShowFlatIcon from "~/components/atoms/Icons/ContactShowFlatIcon";
import DarkContactBrochureIcon from "~/components/atoms/Icons/DarkContactBrochureIcon";
import DarkContactPriceListIcon from "~/components/atoms/Icons/DarkContactPriceListIcon";
import DarkContactShowFlatIcon from "~/components/atoms/Icons/DarkContactShowFlatIcon";
import { SelectOptions } from "~/components/atoms/SelectBox/SingleSelect";
import { IOption } from "~/components/molecules/CheckboxGroup/type";

export const OPTIONS_TENURE: { id: ETenure; name: string }[] = [
  {
    id: ETenure.FREE_HOLD,
    name: "Freehold",
  },
  {
    id: ETenure.YR_99,
    name: "99 years",
  },
  {
    id: ETenure.YR_101,
    name: "101 years",
  },
  {
    id: ETenure.YR_999,
    name: "999 years",
  },
];

export const OPTIONS_CONTACT_SALE_INTERESTED: IOption[] = [
  {
    id: "option1",
    name: "Brochure (A copy will be sent to your WhatsApp)",
    icon: <ContactBrochureIcon />,
    secondIcon: <DarkContactBrochureIcon />,
  },
  {
    id: "option2",
    name: "Price List",
    icon: <ContactPriceListIcon />,
    secondIcon: <DarkContactPriceListIcon />,
  },
  {
    id: "option3",
    name: "Showflat Appointment",
    icon: <ContactShowFlatIcon />,
    secondIcon: <DarkContactShowFlatIcon />,
  },
];

export const OPTION_ANY: IOption = {
  id: "any",
  name: "All",
};

export const PAGE = 1;
export const LIMIT = 50;

export const MAX_LIMIT = 50;

export const FORMAT_FULLDAY = "YYYY-MM-DDTHH:mm";

export const UNIT_TYPE_OPTIONS: SelectOptions[] = [
  {
    id: EUnitType.ONE_BEDROOM,
    name: "1 Bedroom",
  },
  {
    id: EUnitType.TWO_BEDROOM,
    name: "2 Bedroom",
  },
  {
    id: EUnitType.THREE_BEDROOM,
    name: "3 Bedroom",
  },
  {
    id: EUnitType.FOUR_BEDROOM,
    name: "4 Bedroom",
  },
  {
    id: EUnitType.FIVE_BEDROOM,
    name: "5 Bedroom",
  },
  {
    id: EUnitType.STUDIO,
    name: "Studio",
  },
];

export const COUNTRY_PHONE_NUMBER_DIALCODE = "+65"; // Singapore
